import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import GameSidebar from "../Components/GameSidebar";
import Header from "../Components/Header";
import { games } from "../Data/data";
import games1 from "../games1.json"
import { Helmet } from 'react-helmet';
import gameToCategory from "../categories.json"
import gameToRanking from "../rankings.json"
import { scramble }  from "../utils/random"


const Activities = () => {
  const [category, setCategory] = useState("");
  const [filteredGames, setFilteredGames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  let date = new Date();
  let hour = date.getHours() * 60 * 60 + date.getMinutes() + 60 + date.getSeconds();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const turnIntoTitle = title => {
    let result = "";
    let words = title.split("-");
    words.forEach((word) => {
      result = result + word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });

    return result;
  }

  useEffect(() => {
    // Filter the games based on category and search term
    let filtered = games1.filter(game => {
      let matchesCategory = category === '' || (gameToCategory[game.name] || []).includes(category);
      if (!gameToCategory[game.name] && category === "Other") {
        matchesCategory = true;
      }
      const matchesSearchTerm = searchTerm === '' || turnIntoTitle(game.name).toLowerCase().includes(searchTerm.toLowerCase());
      return matchesCategory && matchesSearchTerm;
    });

    let numGamesIncluded = 0;
    filtered.forEach(game => {
      if(Object.keys(gameToRanking).includes(game.name)) {
        numGamesIncluded += 1;
      }
    });
    
    filtered.sort((a, b) => {
      return (gameToRanking[a.name] || 10000000) - (gameToRanking[b.name] || 10000000)
    });
    setFilteredGames(filtered);
  }, [category, searchTerm]);

  return (
    <>
    <Helmet>
      <title>Mathpapers Activities</title>
      <meta name="description" content="A series of interactive activities to practice mathematics"/>
      <meta property="og:type" content="article"/>
      <meta property="og:title" content="Mathpapers Activities"/>
      <meta property="og:description" content="A series of interactive activities to practice mathematics"/>
      <meta property="og:url" content={window.location.href}/>
      <meta property="article:modified_time" content={new Date().toJSON()}/>
      <meta property="og:image" content={`${window.location.origin}/assets/hero.jpg`}/>
      <meta property="og:image:width" content="100"/>
      <meta property="twitter:image" content={`${window.location.origin}/assets/hero.jpg`}/>
      <meta property="twitter:image:src" content={`${window.location.origin}/assets/hero.jpg`}/>
      <meta property="og:image:height" content="100"/>
      <meta name="twitter:image:height" content="100" data-react-helmet="true"/>
      <meta name="twitter:image:width" content="100" data-react-helmet="true"/>
      <meta property="og:image:type" content={"image/jpg"}/>
      <meta property="twitter:url" content={window.location.href}/>
      <meta property="twitter:title" content="Mathpapers Activities"/>
      <meta property="twitter:description" content="A series of interactive activities to practice mathematics"/>
      <meta property="og:type" content="article"/>
      <meta name="msapplication-TileImage" content={`${window.location.origin}/assets/hero.jpg`}/>
    </Helmet>
    <div className="w-full">
      <Header />
      <div className="w-full h-screen flex">
        <div className="lg:w-[22rem]">
          <GameSidebar
            category={category}
            setCategory={setCategory}
          />
        </div>
        <div className="lg:w-[calc(100%-22rem)] w-full h-screen pt-32 lg:pr-10">
          <div className="w-full sm:px-0 flex lg:justify-start justify-end relative px-8">
            <div className="lg:w-full w-11/12 relative md:px-0 lg:pr-0 !pr-10">
              <BiSearch className="text-2xl absolute text-zinc-300 left-2 top-1/2 -translate-y-1/2" />
              <input
                type="search"
                className="w-full p-2 capitalize rounded-lg bg-transparent border outline-none pl-10"
                placeholder="Type here to search the game..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
            <div style={{maxHeight: '70vh', overflow: "scroll"}} className="flex flex-wrap sm:pl-10 pl-0 md:justify-start justify-center items-start gap-4">
              {filteredGames.map((game) => {

                let gameName = "axhz12" + scramble(game.name.replace(/\s+/g, "-"), hour) + "bcn97g"
                
                return (
                <div className="game-box-square" style={{width: "150px", height: "150px", margin: '1.5rem'}}>
                  <Link
                    to={`/worksheet/${gameName}`}
                    className=""
                    state={{ hour }}
                  >
                    <img
                    style={{borderRadius: '2rem', border: 'solid white 5px'}}
                      src={game.image_to_choose === -1 ? game.potential_images[0]: game.potential_images[game.image_to_choose]}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </Link>
                  <h5 style={{margin: 'auto', textAlign: 'center', paddingTop: '0.5rem'}}>{turnIntoTitle(game.name)}</h5>
                </div>
              )})}
              
            </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Activities;
