import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import BlogCard from "../Components/BlogCard";
import BlogSec from "../Components/BlogSec";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { blog1 } from "../Data/data";
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet> 
       <meta
          name="description"
          content="A unique and insightful blog that helps students rediscover their love of mathematics through interactive lessons"
        />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Mathpapers"/>
        <meta property="og:url" content={window.location.href}/>
        <meta property="article:modified_time" content={new Date().toJSON()}/>
        <meta property="og:image" content={`${window.location.origin}/assets/hero.jpg`}/>
        <meta property="og:image:width" content="100"/>
        <meta property="twitter:image" content={`${window.location.origin}/assets/hero.jpg`}/>
        <meta property="twitter:image:src" content={`${window.location.origin}/assets/hero.jpg`}/>
        <meta property="og:image:height" content="100"/>
        <meta name="twitter:image:height" content="100" data-react-helmet="true"/>
        <meta name="twitter:image:width" content="100" data-react-helmet="true"/>
        <meta property="og:image:type" content="image/jpg"/>
        <meta property="twitter:url" content={window.location.href}/>
        <meta property="twitter:title" content="Mathpapers"/>
        <meta property="og:type" content="article"/>
        <meta name="msapplication-TileImage" content={`${window.location.origin}/assets/hero.jpg`}/>
    
    </Helmet>
    <div className="w-full ">
      <Header />
      <div className="w-full pt-24">
        <div className="w-full h-full gap-10 grid-col-2 text-center xl:py-10 xl:pb-0 xl:px-20 ">
          <div className="relative xl:h-[31.5rem] h-screen w-full">
            {/* <img
              src="/assets/images/hero.jpg"
              alt=""
              className="w-full h-full xl:rounded-xl"
            /> */}
            <div className="flex flex-col items-center justify-center w-full h-full px-8 absolute top-0 left-0">
              <h1 className="lg:text-5xl md:text-4xl text-3xl font-semibold">
                The Beautiful World of Mathematics
              </h1>
              <h4 className="mt-2 xl:text-2xl text-xl">
                Unlocking the Power of Math Education
              </h4>
              <p className="mt-5 lg:w-2/3 md:text-lg text-sm">
                Explore the limitless possibilities of learning and teaching
                mathematics in an innovative digital environment. Dive into
                interactive lessons, engage with cutting-edge resources, and
                embark on a journey to mastery.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BlogSec />
      <Footer />
    </div>
    </>
  );
};

export default Home;
