import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Activities from "./Pages/Activities";
import AdditionWorksheets from "./Pages/AdditionWorksheets";
import Blog1 from "./Pages/Blogs/Blog1";
import Blog10 from "./Pages/Blogs/Blog10";
import Blog11 from "./Pages/Blogs/Blog11";
import Blog12 from "./Pages/Blogs/Blog12";
import Blog13 from "./Pages/Blogs/Blog13";
import Blog14 from "./Pages/Blogs/Blog14";
import Blog15 from "./Pages/Blogs/Blog15";
import Blog16 from "./Pages/Blogs/Blog16";
import Blog17 from "./Pages/Blogs/Blog17";
import Blog18 from "./Pages/Blogs/Blog18";
import Blog19 from "./Pages/Blogs/Blog19";
import Blog2 from "./Pages/Blogs/Blog2";
import Blog20 from "./Pages/Blogs/Blog20";
import Blog3 from "./Pages/Blogs/Blog3";
import Blog4 from "./Pages/Blogs/Blog4";
import Blog5 from "./Pages/Blogs/Blog5";
import Blog6 from "./Pages/Blogs/Blog6";
import Blog7 from "./Pages/Blogs/Blog7";
import Blog8 from "./Pages/Blogs/Blog8";
import Blog9 from "./Pages/Blogs/Blog9";
import DivisionWorksheets from "./Pages/DivisionWorksheets";
import Game from "./Pages/Game";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import MultiplicationWorksheets from "./Pages/MultiplicationWorksheets";
import PracticePapers from "./Pages/PracticePapers";
import SubtractionWorksheets from "./Pages/SubtractionWorksheets";
import GraphicFractions from "./Pages/GraphicFractions";

import { blogs } from "./Data/data";
import { useEffect, componentWillMount } from "react";
import { v4 as uuidv4 } from 'uuid';

const App = () => {

  if (!localStorage.getItem("like_tracking_token")) {
    localStorage.setItem("like_tracking_token", crypto.randomUUID());
  }
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/worksheet/:name" element={<Game />} />
        <Route path="/blog/Area-of-circles-and-sectors" element={<Blog1 image={`${window.location.origin}/${blogs[0].img}`} description={blogs[0].desc} title={blogs[0].title}/>} />
        <Route path="/blog/Comparing-fractions" element={<Blog2 image={`${window.location.origin}/${blogs[1].img}`} description={blogs[1].desc} title={blogs[1].title} />} />
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/terms" element={<Terms/>}/>
        <Route
          path="/blog/Converting-between-mixed-numbers-and-improper-fractions"
          element={<Blog3 image={`${window.location.origin}/${blogs[2].img}`} description={blogs[2].desc} title={blogs[2].title}/>}
        />
        <Route
          path="/blog/Equivalent-Fractions-Made-Simple"
          element={<Blog4 image={`${window.location.origin}/${blogs[3].img}`} description={blogs[3].desc} title={blogs[3].title}/>}
        />
        <Route
          path="/blog/Factorizing-polynomials-upto-2nd-degree"
          element={<Blog5 image={`${window.location.origin}/${blogs[4].img}`} description={blogs[4].desc} title={blogs[4].title} />}
        />
        <Route path="/blog/Graphing-Straight-Lines" element={<Blog6 image={`${window.location.origin}/${blogs[5].img}`} description={blogs[5].desc} title={blogs[5].title}/>} />
        <Route
          path="/blog/Identifying-and-Classifying-quadrilaterals"
          element={<Blog7 image={`${window.location.origin}/${blogs[6].img}`} description={blogs[6].desc} title={blogs[6].title} />}
        />
        <Route
          path="/blog/Identifying-and-Classifying-triangles"
          element={<Blog8 image={`${window.location.origin}/${blogs[7].img}`} description={blogs[7].desc} title={blogs[7].title}/>}
        />
        <Route path="/blog/Introduction-to-statistics" element={<Blog9 image={`${window.location.origin}/${blogs[8].img}`} description={blogs[8].desc} title={blogs[8].title}/>} />
        <Route
          path="/blog/Modeling-real-world-problems-with-linear-inequalities"
          element={<Blog10 image={`${window.location.origin}/${blogs[9].img}`} description={blogs[9].desc} title={blogs[9].title}/>}
        />
        <Route
          path="/blog/Multiplying-and-Dividing-decimals"
          element={<Blog11 image={`${window.location.origin}/${blogs[10].img}`} description={blogs[10].desc} title={blogs[10].title} />}
        />
        <Route
          path="/blog/Multiplying-and-dividing-fractions"
          element={<Blog12 image={`${window.location.origin}/${blogs[11].img}`} description={blogs[11].desc} title={blogs[11].title}/>}
        />
        <Route
          path="/blog/Pythagorean-Theorem-and-Its-Applications"
          element={<Blog13 image={`${window.location.origin}/${blogs[12].img}`} description={blogs[12].desc} title={blogs[12].title} />}
        />
        <Route path="/blog/Rates-ratios-and-proportions" element={<Blog14 image={`${window.location.origin}/${blogs[13].img}`} description={blogs[13].desc} title={blogs[13].title} />} />
        <Route path="/blog/Solving-linear-equations" element={<Blog15 image={`${window.location.origin}/${blogs[14].img}`} description={blogs[14].desc} title={blogs[14].title}/>} />
        <Route path="/blog/System-of-Linear-Equations" element={<Blog16 image={`${window.location.origin}/${blogs[15].img}`} description={blogs[15].desc} title={blogs[15].title}/>} />
        <Route
          path="/blog/The-Power-of-Order-of-Operations"
          element={<Blog17 image={`${window.location.origin}/${blogs[16].img}`} description={blogs[16].desc} title={blogs[16].title}/>}
        />
        <Route
          path="/blog/Transformations-Rotation-and-Reflection"
          element={<Blog18 image={`${window.location.origin}/${blogs[17].img}`} description={blogs[17].desc} title={blogs[17].title}/>}
        />
        <Route
          path="/blog/Understanding-different-representations-of-numbers"
          element={<Blog19 image={`${window.location.origin}/${blogs[18].img}`} description={blogs[18].desc} title={blogs[18].title}/>}
        />
        <Route
          path="/blog/Understanding-Inverse-Proportion"
          element={<Blog20 image={`${window.location.origin}/${blogs[19].img}`} description={blogs[19].desc} title={blogs[19].title}/>}
        />
        <Route
          path="/practice/addition-worksheets"
          element={<AdditionWorksheets />}
        />
        <Route
          path="/practice/subtraction-worksheets"
          element={<SubtractionWorksheets />}
        />
        <Route
          path="/practice/multiplication-worksheets"
          element={<MultiplicationWorksheets />}
        />
        <Route
          path="/practice/division-worksheets"
          element={<DivisionWorksheets />}
        />
        <Route
          path="/practice/graphic-fractions-worksheets"
          element={<GraphicFractions />}
        />
      </Routes>
    </Router>
  );
};

export default App;
