const Footer = () => {
  return <>
  <footer className="px-20 p-10 text-xl font-semibold bg-gray-900">
  Mathpapers 2020 <br/><br/>
  <div className="cursor-pointer text-center">
    <a href="/" className="hover:text-blue-500">Home</a>    |    <a href="/about" className="hover:text-blue-500">About Us</a>    |    <a href="/contact" className="hover:text-blue-500">Contact Us</a>    |    <a href="/privacy" className="hover:text-blue-500">Privacy Policy</a>    |    <a href="/terms" className="hover:text-blue-500">Terms and Conditions</a>
  </div>
  </footer>

  </>;
};

export default Footer;
