import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Privacy = () => {
    return (
        <>
        <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="title" content="Privacy Policy | MathPapers.org"/>
        <meta name="description" content="Learn about MathPapers.org's privacy practices. We prioritize your privacy and only collect minimal data through Google Analytics to enhance user experience."/>
        <meta name="keywords" content="privacy policy, data protection, cookies, Google Analytics, children's privacy, MathPapers.org"/>
        <meta name="robots" content="index, follow"/>
        <title>Privacy Policy | MathPapers.org</title>
        </Helmet>
        <div className="w-full pt-24">
            <Header/> 
            <div class="max-w-4xl mx-auto py-12 px-6">
        <h1 class="text-4xl font-bold text-center text-blue-600 mb-8">Privacy Policy</h1>

        <p class="text-lg text-center mb-8">Effective Date: August 27, 2024</p>

        <section class="mb-12">
            <p class="text-lg leading-relaxed">At MathPapers.org, we are committed to protecting your privacy. This Privacy Policy explains how we handle any personal information we collect through our website, <a href="https://www.mathpapers.org" class="text-blue-600 underline">https://www.mathpapers.org</a>. Please read this policy carefully to understand our practices regarding your data and how we will treat it.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Information We Collect</h2>
            <h3 class="text-xl font-semibold mb-2">1. Basic Request Logging:</h3>
            <p class="text-lg leading-relaxed">We use Google Analytics to collect basic information about visitors to our site. This information includes your IP address, browser type, operating system, referral URLs, and the pages you visit. Google Analytics may place cookies on your browser to collect this data.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">How We Use Your Information</h2>
            <p class="text-lg leading-relaxed">The information collected by Google Analytics is used solely to improve our website. We analyze this data to understand how users interact with our site, which helps us enhance the content, layout, and overall user experience.</p>
            <p class="text-lg leading-relaxed"><strong>We do not collect, store, or process any personally identifiable information (PII)</strong> unless you voluntarily provide it through contacting us directly.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Cookies</h2>
            <p class="text-lg leading-relaxed">Cookies are small text files that are placed on your computer or mobile device when you visit a website. MathPapers.org uses cookies through Google Analytics to track usage patterns and enhance your experience. These cookies help us understand how you use the site, enabling us to improve our content.</p>
            <p class="text-lg leading-relaxed">You can control or delete cookies through your browser settings at any time. However, please note that disabling cookies may affect the functionality of our site.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Third-Party Services</h2>
            <h3 class="text-xl font-semibold mb-2">Google Analytics:</h3>
            <p class="text-lg leading-relaxed">We use Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies to help us analyze how visitors use the site. The information generated by the cookie about your use of the website will be transmitted to and stored by Google on servers in the United States.</p>
            <p class="text-lg leading-relaxed">For more information on Google Analytics and its privacy practices, please visit <a href="https://policies.google.com/privacy" class="text-blue-600 underline">Google's Privacy Policy</a>.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Data Security</h2>
            <p class="text-lg leading-relaxed">While we do not directly collect personal information, we are committed to ensuring that the data processed through Google Analytics is kept secure. However, please understand that no method of data transmission over the Internet is 100% secure. We cannot guarantee the absolute security of your data, but we strive to use commercially acceptable means to protect it.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Children's Privacy</h2>
            <p class="text-lg leading-relaxed">MathPapers.org is designed to provide educational content for children, but we do not knowingly collect any personal data from children under the age of 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information from our systems.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Your Rights and Choices</h2>
            <p class="text-lg leading-relaxed">As a user of MathPapers.org, you have the right to:</p>
            <ul class="list-disc list-inside text-lg leading-relaxed space-y-2">
                <li>Opt-out of Google Analytics by using the <a href="https://tools.google.com/dlpage/gaoptout" class="text-blue-600 underline">Google Analytics Opt-out Browser Add-on</a>.</li>
                <li>Adjust your browser settings to manage cookies.</li>
            </ul>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Changes to This Privacy Policy</h2>
            <p class="text-lg leading-relaxed">We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. Any updates will be posted on this page with a revised "Effective Date." We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
        </section>

        <section>
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Contact Us</h2>
            <p class="text-lg leading-relaxed">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:contact@mathpapers.org" class="text-blue-600 underline">contact@mathpapers.org</a>.</p>
        </section>
    </div>
            
            <Footer/>
        </div>
        </>
    )

}

export default Privacy;