import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'




const Modal = ( {hide, setHide, gameId} ) => {
    const [area, setArea] = useState("")


    const handleClickOnAnywhereButModal = (e) => {
      console.log(e.target.id);
      if (e.target.id === "feedback-modal") {
        console.log(`Setting hide to ${!hide}`);
        setHide(true);
      }
    }

    useEffect(() => {

      window.addEventListener("click", handleClickOnAnywhereButModal);

      return () => {
        window.removeEventListener("click", handleClickOnAnywhereButModal);
      };
    }, []);

    const handleSubmit = () => {
        //TODO add some way to notify use that their feedback has been received
        fetch('https://soccerai.pro/interact', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({user_id: localStorage.getItem("like_tracking_token"), interaction_type: "feedback", game_id: gameId, additional_information: area})
          }).then(res => res.json()).then(data => {

          }).catch(e => {
            console.log("error " + e);
          });
        setHide(true);

      }

    return (
        <div id="feedback-modal" className="fixed left-0 top-0 w-full h-full opacity-0 invisible" style={hide ? {} : {transform: "scale(1.1)", transition: "visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s", opacity: "1", visibility: "visible", transform: "scale(1.0)", transition: "visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s"}}>
        <div style={{transform: "translate(-50%, -50%)", padding: "1rem 1.5rem", borderRadius: "0.5rem"}} className="absolute left-1/2 top-1/2 bg-gray-500 w-3/5">
        <FontAwesomeIcon onClick={() => setHide(!hide)}  className="h-8 mx-3 color-gray cursor-pointer text-gray-300 w-3" icon={faX} />

            <textarea onChange={e => setArea(e.target.value)}className="text-black w-full h-3/5 p-2 bg-gray-500 outline-none resize-none" placeholder="This game sucks...">{area}</textarea>
            <br/>
            <button onClick={() => handleSubmit()} id="button" type="submit" class="bg-indigo-600 shadow-xl hover:bg-indigo-500 text-white font-bold rounded-full p-4 w-48 float-right">Submit</button>
        </div>


        </div>
    )
}

export default Modal;