import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const About = () => {
return (
    <>
    <Helmet>

<meta name="title" content="MathPapers.org | Fun and Educational Math Content for Kids"/>
<meta name="description" content="Explore MathPapers.org for engaging math blogs, fun activities, and resources that make learning math enjoyable for kids, parents, and teachers."/>
<meta name="keywords" content="math for kids, math blogs, educational activities, printable worksheets, math resources, kids math games, learn math, math learning tools, math tips for parents, math tips for teachers"/>
<meta name="author" content="MathPapers.org Team"/>
<meta name="robots" content="index, follow"/>
<meta name="language" content="English"/>
<meta name="distribution" content="global"/>

<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.mathpapers.org/"/>
<meta property="og:title" content="MathPapers.org | Fun and Educational Math Content for Kids"/>
<meta property="og:description" content="MathPapers.org offers fun and educational math content for kids, including blogs, activities, and resources for parents and teachers."/>
<meta property="og:image" content="https://www.mathpapers.org/images/social-share-image.jpg"/>

<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://www.mathpapers.org/"/>
<meta property="twitter:title" content="MathPapers.org | Fun and Educational Math Content for Kids"/>
<meta property="twitter:description" content="Check out MathPapers.org for exciting math blogs, interactive activities, and more to make learning math fun for kids."/>
<meta property="twitter:image" content="https://www.mathpapers.org/images/twitter-share-image.jpg"/>

<link rel="canonical" href="https://www.mathpapers.org/"/>

<meta name="rating" content="General"/>
<meta name="revisit-after" content="7 days"/>
<meta name="coverage" content="Worldwide"/>
<meta name="target" content="all"/>
<meta name="audience" content="children, parents, teachers"/>
<meta name="theme-color" content="#1a202c"/>
<meta name="msapplication-navbutton-color" content="#1a202c"/>
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>


        <title>About Us | MathPapers.org</title>
    </Helmet>
    <div className="w-full pt-24">
        <Header/> 
        <div class="max-w-4xl mx-auto py-12 px-6">
        <h1 class="text-4xl font-bold text-center text-blue-600 mb-8">About Us</h1>
        
        <p class="text-lg text-center mb-8">Welcome to MathPapers.org! We're your go-to destination for fun, engaging, and educational math content tailored especially for kids. Our mission is to make math exciting and accessible, inspiring a love for numbers, problem-solving, and creative thinking in young minds.</p>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Who We Are</h2>
            <p class="text-lg leading-relaxed">At MathPapers.org, we believe that learning math should be an adventure. Our dedicated team of educators, math enthusiasts, and content creators work tirelessly to bring you a variety of resources designed to make math enjoyable. Whether you're a parent, teacher, or a young learner, you'll find something here that sparks curiosity and encourages exploration.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">What We Offer</h2>
            <ul class="list-disc list-inside text-lg leading-relaxed space-y-2">
                <li><strong>Math Blogs:</strong> Dive into our regularly updated blog section, where we share fascinating articles, fun facts, and tips to help kids master math concepts. Our blogs cover everything from basic arithmetic to more advanced topics, all explained in a kid-friendly way.</li>
                <li><strong>Educational Activities:</strong> Explore our collection of interactive activities, games, and puzzles that make learning math a hands-on experience. These activities are designed to reinforce learning while keeping it fun and engaging.</li>
                <li><strong>Printable Worksheets:</strong> Need some extra practice? Our free, printable math worksheets are perfect for kids who want to hone their skills at their own pace. These resources are suitable for different grade levels and cover a wide range of topics.</li>
                <li><strong>Parent & Teacher Resources:</strong> We provide valuable resources and tips for parents and teachers to support their kids’ learning journey. From strategies to make math fun at home to classroom activities that encourage participation, we've got you covered.</li>
            </ul>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Our Vision</h2>
            <p class="text-lg leading-relaxed">We aim to transform the way kids perceive math by offering resources that are not just educational but also entertaining. By nurturing a positive attitude towards math from an early age, we hope to empower children with the confidence and skills they need to succeed in the classroom and beyond.</p>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Join Our Community</h2>
            <p class="text-lg leading-relaxed">MathPapers.org is more than just a website; it's a community. We invite you to connect with us on social media, subscribe to our newsletter, and share your experiences. Together, we can make math a fun and integral part of every child's life.</p>
        </section>

        <section>
            <h2 class="text-2xl font-semibold text-blue-500 mb-4">Contact Us</h2>
            <p class="text-lg leading-relaxed">Have questions, suggestions, or feedback? We’d love to hear from you! Reach out to us at <a href="mailto:contact@mathpapers.org" class="text-blue-600 underline">contact@mathpapers.org</a> or through our <a href="/contact" class="text-blue-600 underline">contact page</a> and let’s work together to make math learning an exciting journey.</p>
        </section>
    </div>

        <Footer/>
    </div>
    </>)
}

export default About;